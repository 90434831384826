import { AppRoutes } from './app-routes';
import { docsUrl } from "./social-links";

export interface NavigationItem {
  label: string;
  url: string;
  type: 'href' | 'routerLink';
  target?: string;
  rel?: string;
}

export const NAVIGATION_ITEMS: NavigationItem[] = [
  { label: 'Services', url: `${AppRoutes.SERVICE}`, type: 'routerLink' },
  {
    label: 'Docs',
    url: docsUrl,
    type: 'href',
    target: '_blank',
    rel: 'nofollow noopener noreferrer',
  },
  { label: 'Blog', url: `${AppRoutes.BLOG_LIST}`, type: 'routerLink' },
];
