import { trigger, state, style, transition, animate } from '@angular/animations';

export const headerMobileAnimation = [
  trigger('lineState', [
    state('initial', style({ transform: 'none' })),
    state('rotated1', style({ transform: 'rotate(45deg) translate(6px, 3px)' })),
    state('rotated2', style({ transform: 'rotate(-45deg) translate(3px, -2px)' })),
    transition('initial <=> rotated1, initial <=> rotated2', animate('0.3s ease')),
  ]),
  trigger('fade', [
    transition(':enter', [style({ opacity: 0 }), animate('0.3s ease', style({ opacity: 1 }))]),
    transition(':leave', [animate('0.3s ease', style({ opacity: 0 }))]),
  ]),
];
